import {Injectable} from "@angular/core";
import {AlertController} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(public alertController: AlertController) {
    }

    async presentError(message: string) {
        const alert = await this.alertController.create({
            header: 'Error',
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }


    async presentMessage(message: string, header: string = '') {
        const alert = await this.alertController.create({
            header: header,
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }
}
