import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {map, tap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

export class PhoneNumber {
    IsDefault: boolean;
    IsDeleted: boolean;
    PhoneNumber: string;
    PrimaryUserId: number;
    UserIds: number[];
}

@Injectable({
    providedIn: "root"
})
export class NotificationSettingsService {
    baseUrl = `${environment.serverUrl}/notifications.api/api/notificationSettings`
    
    phoneNumbers$ = new BehaviorSubject<PhoneNumber[]>([]) ;
    defaultNumber$ = new BehaviorSubject<PhoneNumber>(null);
    selectedPhoneNumber$ = new BehaviorSubject<PhoneNumber>(null);

    constructor(private httpClient: HttpClient,
                private authenticationService: AuthenticationService) {
    }
    
    init() {
        this.getNotificationSettings().subscribe(async result => {
            const settings = await result;
            this.phoneNumbers$.next(settings.phoneNumbers);
            this.defaultNumber$.next(settings.defaultNumber);
            this.selectedPhoneNumber$.next(settings.selectedPhoneNumber);
        })
    }

    getNotificationSettings() {
        return this.httpClient.get<any>(this.baseUrl).pipe(map(async (result) => {
            const resultModel = {
                defaultNumber: null,
                selectedPhoneNumber: null,
                phoneNumbers: []
            };

            const currentUser = await this.authenticationService.getCurrentUser();

            const isHasPermission = currentUser.RoleCodes.some(x => x === 'SysAdmin')
                || currentUser.RoleCodes.some(x => x === 'CA');

            resultModel.defaultNumber = result.PhoneNumberSettings.find((number) => number.IsDefault);
            if (isHasPermission) {
                resultModel.phoneNumbers = result.PhoneNumberSettings;
                resultModel.selectedPhoneNumber = resultModel.selectedPhoneNumber || resultModel.defaultNumber || (resultModel.phoneNumbers || [])[0];
            } else {
                resultModel.phoneNumbers = result.PhoneNumberSettings.filter(n => n.PrimaryUserId === currentUser.Id ||
                    n.UserIds && n.UserIds.some(u => u === currentUser.Id) ||
                    n.IsDefault);

                let numberToSelect = resultModel.phoneNumbers.find(n => n.PrimaryUserId === currentUser.Id);
                if (!numberToSelect) {
                    numberToSelect = resultModel.defaultNumber;
                }
                if (numberToSelect) {
                    resultModel.selectedPhoneNumber = numberToSelect;
                }
            }
            return resultModel;
        }));
    }
}

