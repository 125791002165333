import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    baseUrl = `${environment.serverUrl}/membership.api`;

    currentUser = new BehaviorSubject<any>(null);


    constructor(private http: HttpClient) {
    }

    public getCurrentUser() {
        if (this.getToken()) {
            return new Promise<any>((resolve) => {
                if (this.currentUser.getValue()) {
                    resolve(this.currentUser.getValue());
                } else {
                    this.http.get<any>(this.baseUrl + '/api/user/mypersonalinfo').subscribe(result => {
                        this.currentUser.next(result);
                        resolve(result);
                    })
                }
            })
        } else {
            return null;
        }
    }

    public login(model) {
        return this.http.post<any>(this.baseUrl + '/auth', model).pipe(tap(result => {
            console.log(result)
            this.saveToken(result.value);
            this.getCurrentUser().then(user => {
                this.currentUser.next(user);
            });
        }));
    }

    public getToken(): string {
        return localStorage.getItem('token');
    }

    public saveToken(token) {
        return localStorage.setItem('token', token);
    }

    public isAuthenticated() {
        return !!this.getToken();
    }

    public logout() {
        localStorage.clear();
        this.currentUser.next(null);
    }
}

