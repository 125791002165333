import {Injectable} from "@angular/core";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Router} from "@angular/router";
import {AuthenticationService} from "./authentication.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(public alertService: AlertService,
                private authenticationService: AuthenticationService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: any) => {
                console.log(error);

                let errorMessage = error && (error.error.errorMessage || error.error);

                if (!(typeof errorMessage === 'string')) {
                    errorMessage = 'Error happened';
                }

                this.alertService.presentError(errorMessage);

                if (error.status === 401) {
                    this.authenticationService.logout();
                    this.router.navigate(['/', 'login'])
                }
                return throwError(error);
            }));
    }
}
