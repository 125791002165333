import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {NotificationSettingsService} from "./services/notification-settings.service";
import {AuthenticationService} from "./services/authentication.service";
import {Router} from "@angular/router";
import {skipUntil, skipWhile} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Send Message',
      url: '/send-message',
      icon: 'chatbox'
    },
    {
      title: 'Inbox',
      url: '/inbox',
      icon: 'mail'
    },
    {
      title: 'Options',
      url: '/options',
      icon: 'cog'
    }
  ];

  user: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private notificationSettingsService: NotificationSettingsService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async ngOnInit() {
    const path = window.location.pathname;
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());
    }

    this.user = await this.authenticationService.getCurrentUser();
    this.authenticationService.currentUser.subscribe(user => {
      if (user) {
        this.notificationSettingsService.init();
      }
    })
  }
}
