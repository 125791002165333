import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {LoadingController} from '@ionic/angular';
import {from, Observable} from 'rxjs';
import {finalize, mergeMap} from 'rxjs/operators';

@Injectable()
export class LoadingInterception implements HttpInterceptor {
    constructor(public loadingController: LoadingController) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('background')) {
            return from(this.loadingController.create({
                message: 'Please wait...',
            })).pipe(mergeMap(loading => {
                loading.present();
                return next.handle(req).pipe(finalize(() => {
                    loading.dismiss();
                }));
            }));
        } else {
            return next.handle(req);
        }
    }
}



